




































import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { PatientStatusClicked } from './+state/events';

export default defineComponent({
  setup() {
    return {
      headerInfo: sink.select('patients.detail.header'),
      updatePatientStatus: sink.lazyBroadcast(PatientStatusClicked()),
    };
  },
});
